button.add-button{
    color: #4ea656;
    width: 118px;
}
button.add-button,
button.group-button {
    font-size: 15px;
    height: 27px;
    outline: none;
    border: 1px solid #000000;
}
button.center-group-button,
button.right-group-button {
    border-left-color: transparent;
}