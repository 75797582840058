code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: "Adobe Gurmukhi";
    src: url(../src/Assets/Fonts/AdobeGurmukhi-Regular.otf) format('opentype'),
    url(../src/Assets/Fonts/AdobeGurmukhi-Bold.otf) format('opentype');
}

@font-face {
    font-family: "PlayfairDisplay";
    src: url(../src/Assets/Fonts/playfair-display/PlayfairDisplay-Regular.ttf) format('truetype'),
    url(../src/Assets/Fonts/playfair-display/PlayfairDisplay-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "Montserrat";
    src: url(../src/Assets/Fonts/Montserrat-Regular.ttf) format('truetype'),
    url(../src/Assets/Fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "Open Sans";
    src: url(../src/Assets/Fonts/OpenSans-Regular.ttf) format('truetype'),
    url(../src/Assets/Fonts/OpenSans-Bold.ttf) format('truetype'),
    url(../src/Assets/Fonts/OpenSans-Light.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    background: url('../src/Assets/Images/background_img.png') no-repeat center center fixed;;
    background-size: cover;
}

#page-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.card {
    background-color: rgba(17, 15, 12, 0.63);
    border: 2px solid #C4954D;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 25px 50px;
}

.title-separator {
    width: 40%;
    height: 2px;
    background-color: white;
    margin: 0 30%;
}

.title-separator::before {
    content: '';
    position: absolute;
    margin-top: -2px;
    left: 29%;
    width: 7px;
    height: 7px;
    background-color: white;
    border-radius: 50%;
}

.title-separator::after {
    content: '';
    position: absolute;
    margin-top: -2px;
    right: 29%;
    width: 7px;
    height: 7px;
    background-color: white;
    border-radius: 50%;
}

button.primary-button {
    width: 100%;
    background-color: #000; /*#332412;*/
    border-radius: 0;
    color: #ca8e46;
}

button.secondary-button {
    width: 100%;
    background-color: #ca8e46;
    border-radius: 0;
    color: #332412;
}

button.primary-button.border {
    border: 1px solid #ca8e46 !important;
}

button.secondary-button.border {
    border: 1px solid #332412 !important;
}

button.primary-button.border-radius {
    border-radius: 5px;
}

button.secondary-button.border-radius {
    border-radius: 5px;
}

button.primary-button,
button.secondary-button {
    outline: none;
    box-shadow: unset;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 15px;
}

button.secondary-button:hover {
    box-shadow: unset;
    background-color: #ca8e46;
    color: #ffffff;
}

button.primary-button:hover {
    background-color: #332412;
    box-shadow: unset;
}

button.primary-button.Mui-disabled {
    color: #332412;
    box-shadow: none;
    background-color: #ca8e46;
    opacity: 0.7;
}

button.secondary-button.Mui-disabled {
    color: #332412;
    box-shadow: none;
    background-color: #ffffff;
    opacity: 0.7;
}

button.popup-button {
    width: 50px;
    height: 35px;
    font-size: 12px;
}

.MuiCheckbox-root.custom-checkbox {
    padding: 0;
    color: #ffffff;
}

.MuiButtonBase-root.custom-checkbox.Mui-checked {
    color: #ffffff;
}

.adobe-font {
    font-family: 'PlayfairDisplay', sans-serif;
    font-weight: bold;
}

.MuiDialogActions-root.popup-footer {
    padding: 16px 24px;
}

.error-message.default {
    color: #bf1616;
}