.profile-wrapper {
    max-width: 1180px;
    margin: 0 auto;
    padding: 100px 20px;
    position: relative;
}
@media screen and (max-width: 640px) {
    .profile-wrapper {
        padding: 50px 15px;
    }
}
.user-details {
    color: #ffffff;
    margin-bottom: 5px;
}
.logout-button-wrappper {
    width: 150px;
    display: flex;
    height: 100%;
    float: right;
    align-items: center;
}
.sidebar-menu {
    background-color: #c4945d;
color: #fff;
    padding: 20px 0 20px 20px;
    border-radius: 10px;
}
.sidebar-content {
    padding: 20px 0 20px 20px;
}
.sidebar-menu,
.sidebar-content {
    height: 682px;
}
.list-menu-item {
    padding: 15px;
    font-weight: bold;
    font-size: 15px;
    text-align: right;
    cursor: pointer;
}
.list-menu-item.active {
    background-color: #ffffff;
    color: #c4945d;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.reservation-card {
    background-color: #f5f5f5;
    border-radius: 5px;
    padding: 20px;
}
.reservations-button {
    width: 170px;
    margin-top: 10px;
}
.reservation-card-details.bold-text {
    font-weight: bold;
}
.reservation-card-wrapper {
    max-height: 633px;
    overflow: auto;
}
.status {
    border: 1px solid #c4945d;
    border-radius: 3px;
    padding: 2px;
    font-size: 12px;
}
.buttonProgress {
    color: #c4954d !important;
    margin-top: 10px;
    text-align: center;
}

@media screen and (max-width: 640px) {
    .sidebar-menu {
        height: auto;
    }
    .sidebar-content {
        padding: 20px 0 0 0;
    }
    .card {
        padding: 25px 20px;
    }
    .btn-grid {
        margin: 0 auto;
    }
}