.form-field .custom-date-field {
    outline: none;
    border: 1px solid #8e8d8d;
    background-color: #ffffff;
    padding: 25px 25px 5px 25px;
    width: 100%;
  }
  .form-field.field-border .custom-date-field {
    border-radius: 5px;
  }
  .custom-date-field .MuiInput-underline::after,
  .custom-date-field .MuiInput-underline::before {
      display: none;
  }
  .form-field .custom-date-field .MuiInput-input {
    padding-top: 5px;
    padding-bottom: 2px;
    height: unset;
    font-size: 15px;
  }
  .MuiPickersToolbar-toolbar,  
  .MuiPickersDay-daySelected {
    background-color: #C4954D !important;
  }