.reservation-failure-wrapper {
    max-width: 768px;
    margin: 0 auto;
    padding: 125px 20px;
    position: relative;
}
.reservation-failure-title,
.reservation-failure-desc {
    color: white;
    margin-bottom: 5px;
}
.home-button {
    width: 290px;
    margin: auto;
}
@media screen and (max-width: 640px) {
    .reservation-failure-wrapper {
        padding: 50px 15px;
    }
    .home-button {
        width: auto;
    }
}