.header {
    /*background-image: url('../../Assets/Images/header_bg.png');*/
    background-color: #000;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    position: relative;
    z-index: 1;
}
.header .logo-wrapper {
    text-align: left;
}
.header .logo-wrapper .logo {
    margin-top: 5px;
    margin-left: 30px;
    width: 170px;
}
.logo-wrapper button {
    width: 125px;
    background-color: #c4945d;
    color: #000;
    position: absolute;
    font-size: 12px;
    top: 24px;
    right: 40px;
}

.logo-wrapper button:hover {
    background-color: #be8636;
}

@media screen and (max-width: 640px) {
    .header .logo-wrapper .logo {
        width: 150px;
        margin-top: 5px;
    }
    .logo-wrapper button {
        width: 30px;
        right: 10px;
    }
}