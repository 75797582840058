.login-wrapper {
    max-width: 640px;
    margin: 0 auto;
    position: relative;
    padding: 100px 0;
}
h1.login-title {
    font-size: 50px;
    margin-bottom: 5px;
}
p.login-description {
    margin: 20px 0;
}
.login-title,
.login-description,
.login-heading {
    color: white;
}
.login-form-wrapper {
    margin: 0 40px;
}
.otp-text {
    margin: 10px;
    color: #ffffff;
}
.buttonProgress {
    color: #c4954d !important;
    margin-top: 10px;
}
.resend-link {
    display: block;
    font-size: 14px;
    color: #fae7b1;
    text-decoration: underline;
    cursor: pointer;
    width: 100px;
    margin: 10px auto 0 auto;
}
.resend-link:hover {
    color: #c4954d;
}