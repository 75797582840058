.view-reservation-wrapper {
    max-width: 1180px;
    margin: 0 auto;
    padding: 150px 20px;
    position: relative;
    color: #ffffff;
}
@media screen and (max-width: 640px) {
    .view-reservation-wrapper {
        padding: 50px 15px;
    }
}
.booking-details-info {
    padding: 20px 0;
    color: #ffffff;
}
.booking-details-info h6 {
    padding-top: 10px;
    color: #ffffff;
}
.menu-list-wrapper {
    max-height: 230px;
    overflow: auto;
}
.menu-list-wrapper h6 {
    margin-bottom: 15px;
}
.invoice-button {
    width: 200px;
}
.header-title {
    display: flex;
    padding: 10px 0;
}
.header-title svg {
    font-size: 25px;
    margin-right: 10px;
}
.header-title h4 {
    color: #ffffff;
    margin-bottom: 5px;
}
.header-back-btn {
    cursor: pointer;
}

