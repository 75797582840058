.billing-form-wrapper {
    max-width: 768px;
    position: relative;
    margin: 0 auto;
    padding: 100px 20px;
}
.billing-title {
    font-size: 30px;
}
.billing-title,
.heading-title {
    color: #ffffff;
    margin-bottom: 5px;
}
.terms-and-conditions {
    color: #ffffff;
}
.agree-field {
    display: inline-flex;
}
.agree-field h6 {
    margin: 5px 0;
    padding-left: 5px;
}
.payment-button {
    width: 290px;
    margin: auto;
}
@media screen and (max-width: 640px) {
    .payment-button {
        width: auto;
    }
}
.error-message {
    display: block;
    color: rgb(250, 52, 32);
    font-size: 13px;
    text-align: left;
}
.agree-label {
    color: #fff;
    font-family: 'Open Sans', sans-serif !important;
}
