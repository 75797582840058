.reservation-success-wrapper {
    max-width: 768px;
    margin: 0 auto;
    padding: 125px 20px;
    position: relative;
}
.reservation-success-title,
.reservation-success-desc {
    color: white;
    margin-bottom: 5px;
}
.myaccount-button {
    width: 290px;
    margin: auto;
}
@media screen and (max-width: 640px) {
    .reservation-success-wrapper {
        padding: 50px 15px;
    }
    .myaccount-button {
        width: auto;
    }
}
.qr-code img {
    width: 225px;
    display: block;
    margin: 0 auto;
    padding: 20px 0;
}