.footer-wrapper {
    position: relative;
    width: 100%;
    /* height: 225px; */
    padding: 20px 40px;
    background-color: #000;
}
.footer-menu-button {
    /* font-weight: bold; */
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff !important;
    border: unset;
    padding: 4px 8px;
    border-radius: 2px;
    margin-bottom: 5px;
    display: inline-flex;
    cursor: pointer;
}
.footer-menu-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}
.feedback-button:focus,
.footer-menu-button:focus {
    outline: unset;
}
.feedback-button {
    border: 1px solid #fae7b1;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fae7b1;
    background-color: #c4954d;
    font-weight: bold;
    width: 200px;
    cursor: pointer;
}
.social-button {
    /*border: 1px solid #fae7b1;*/
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 5px;
    color: #fae7b1;
    /*background-color: #c4954d;*/
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
}
.feedback-button:hover, .social-button:hover {
    background-color: #c4945d;
}
.footer-menu-button:not(:last-child) {
    margin-right: 5px;
}
.footer-header-title {
    color: #c4945d;
    margin-bottom: 15px;
}
.address-content {
    color: #ffffff;
    display: block;
}
.social-media-links {
    margin-bottom: 15px;
}
.social-media-links > div {
    display: inline-block;
}
.social-icons {
    color: #ffffff;
    margin-right: 5px;
}
