.form-field .custom-select-field {
    outline: none;
    border: 1px solid #8e8d8d;
    background-color: #ffffff;
    padding: 25px 0px 5px 25px;
    width: 100%;
  }
  .form-field.field-border .custom-select-field {
    border-radius: 5px;
  }
  .MuiInput-underline.custom-select-field::after,
  .MuiInput-underline.custom-select-field::before {
      display: none;
  }
  .form-field .custom-select-field .MuiSelect-root {
    padding-top: 6px;
    padding-bottom: 3px;
    min-height: unset;
    font-size: 15px;
  }
  .form-field .custom-select-field .MuiSelect-select:focus {
      background-color: transparent;
  }