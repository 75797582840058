/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-field input.input-field {
  outline: none;
  border: 1px solid #8e8d8d;
  background-color: #ffffff;
  padding: 25px 25px 5px 25px;
  width: 100%;
}
.form-field.field-border input.input-field {
  border-radius: 5px;
}
.form-field label{
  font-size: 10px;
  position: absolute;
  margin-top: 10px;
  margin-left: 26px;
  color: #8e8d8d;
  text-transform: uppercase;
  z-index: 99;
}
.form-field sup {
  font-size: 100%;
  color: red;
}