.booking-form-wrapper {
    max-width: 768px;
    margin: 0 auto;
    padding: 110px 20px;
    position: relative;
}

.booking-title {
    font-size: 30px;
}

.booking-title,
.slots-title {
    color: #ffffff;
    margin-bottom: 5px;
}

.slot-wrapper {
    cursor: pointer;
}

.slot-wrapper.slot-disabled {
    cursor: not-allowed;
}

.time-slot-chip {
    background-color: white;
    font-size: 12px;
    padding: 5px;
    text-align: center;
    border: 1px solid #8e8d8d;
    border-radius: 5px;
}

.time-slot-chip.disabled {
    pointer-events: none;
    background-color: #c5c5c5;
}

.time-slot-chip.disabled.red {
    background-color: #d02f26;
    border: 1px solid #d02f26;
    color: #fff;
}

.time-slot-chip.active {
    color: #c4945d;
    background-color: #fae7b1;
}

.menu-wrapper {
    margin: 0 40px;
    /*max-height: 550px;*/
    overflow: auto;
}

.menu-card {
    margin-bottom: 10px;
}

.menu-item-description,
.menu-item-header,
.menu-item-footer {
    padding: 5px 20px;
}

.menu-item-header {
    background-color: #c4945d;
    border-radius: 15px 15px 0 0;
}

.menu-item-footer {
    border-radius: 0 0 15px 15px;
    background-color: #ffffff;
}

.menu-item-header h5 {
    color: #ffffff;
}

.menu-item-description {
    margin-bottom: 0;
    background-color: #ffffff;
    font-size: 12px;
}

.menu-item-footer h5,
.menu-item-header h5 {
    margin-bottom: 0;
}

.submit-booking-button {
    width: 290px;
    margin: auto;
}

.details-tab {
    margin: 0 40px 20px 40px;
}

.food-category {
    background-color: #ffffff;
    width: 15px;
}


.sub-title {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    padding: 3px;
    color: #fff;
    background-color: #c70421;
    width: 50%;
    /*width: 80%;*/
    /*color: #c4945d;*/
    /*background-color: #fae7b1;*/
    margin: 0 auto;
}

.addon-modal {
    width: 50%;
    margin: 0 auto;
    height: 90%;
    box-shadow: 24px;
    padding: 2rem;
}

@media screen and (max-width: 640px) {
    .menu-wrapper {
        margin: 0;
    }

    .sub-title {
        width: 100%;
    }

    .submit-booking-button {
        width: auto;
    }

    .addon-modal {
        width: 95%;
    }

    .details-tab {
        margin: 10px;
    }
}

.bookings-message {
    color: white;
    margin-top: 5px;
    font-size: 12px;
}

.addon-button {
    background: "#ca8e46";
    color: white;
}